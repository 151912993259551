import cn from 'classnames';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { actions } from '../../redux';

const Notification = () => {
  const { title, message, isShowing } = useSelector((state) => state.notification);
  const dispath = useDispatch();

  const notificationClassnames = cn('notification', {
    'notification--show': isShowing,
  });

  useEffect(() => {
    let timeout;
    if (isShowing) {
      timeout = setTimeout(() => {
        dispath(actions.hideNotification());
      }, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, message, isShowing]);

  const clickHandler = () => dispath(actions.hideNotification());

  return (
    <div onClick={clickHandler} className={notificationClassnames}>
      <div className="notification__inner">
        <span className="notification__title">{title}</span>
        <p className="notification__text">{message}</p>
      </div>
    </div>
  );
};

export default Notification;
